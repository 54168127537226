import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire//firestore';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import KenBurnsSlideshow from './kbslideshow';
@Component({
  selector: 'slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements AfterViewInit {
  downloadURL: Observable<string>;
  $photos: Observable<any[]>;
  slideshow: KenBurnsSlideshow;

  constructor(private db: AngularFirestore) {}

  trackByFn(index, item) {
    return item.path; // or item.id
  }

  ngAfterViewInit() {
    this.$photos = this.db
      .collection('photos')
      .valueChanges()
      .pipe(
        map(photos =>
          this.shuffle(
            photos.map((p: { path: string; size: number }) => {
              return {
                ...p,
                path:
                  'https://eventpic.imgix.net/' +
                  p.path +
                  '?w=1920&h=1080&auto=format,enhance&q=60&crop=entropy&fit=crop&gam=10&sat=10&con=10&exp=2'
              };
            })
          )
        ),
        tap(data => console.log(data)),
        tap(data =>
          setTimeout(() => {
            if (!!this.slideshow) {
              this.slideshow.update();
            } else {
              this.initSlideShow();
            }
          }, 500)
        )
      );
  }

  private initSlideShow() {
    setTimeout(() => {
      const elems = document.querySelectorAll('.slideshow');
      [].forEach.call(elems, function(el) {
        el.classList.remove('hidden');
      });
      const ssEl = document.querySelector('.slideshow');
      console.log(ssEl);
      // Construct slideshow
      this.slideshow = new KenBurnsSlideshow({
        el: ssEl
      });
      // Init slideshow
      this.slideshow.init();
      console.log('slideshow started');
    }, 3000);
  }

  private shuffle(a) {
    let j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }
}
